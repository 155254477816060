/*Base URL*/
export const BASE_URL = "https://uat.tradingdox.com";
export const LOCAL_BASE_URL = BASE_URL + "/api/v1";
export const ACTION_CABLE_URL = BASE_URL + "/cable";
export const STUB_BASE_URL = "https://private-71cb45-tradingdox1.apiary-mock.com" + "/api/v1";
/*Auth*/
export const HF_AUTH = LOCAL_BASE_URL + "/user_management/hf/auth";
export const LOGIN = "/user_management/user/auth/login";
export const CHANGE_PASSWORD = "/user_management/user/auth/change_password";
export const SIGNUP = HF_AUTH + "/signup";
export const VERIFY_ACCOUNT = HF_AUTH + "/confirm_verification_code";
export const RESEND_OTP = HF_AUTH + "/resend_verification_code";
export const FORGOT_PASSWORD = "/user_management/user/auth/forgot_password";
export const RESET_PASSWORD = "/user_management/user/auth/password";
export const SET_MFA = "/user_management/user/auth/mfa";
export const AUTHENTICATE_OTP = "/user_management/user/auth/authenticate_otp"
export const ENABLE_DISABLE_MFA = "/user_management/user/auth/enable_disable_2fa";
export const GENERATE_BACKUP_CODES = "/user_management/user/auth/generate_backup_codes"

/*Management*/
export const USER_MANAGEMENT = LOCAL_BASE_URL + "/user_management/hl";
export const COMPANY_MANAGEMENT = LOCAL_BASE_URL + "/company_management/hl";
export const HF_COMPANY_MANAGEMENT = LOCAL_BASE_URL + "/company_management/hf";

export const SUBSCRIBE_PLAN =
  HF_COMPANY_MANAGEMENT + "/companies/:companyId/update_subscription_plan";

/*HFund*/
export const HFUND_COMPANIES = COMPANY_MANAGEMENT + "/companies";
export const MANAGE_HFUND_COMPANY =
  HF_COMPANY_MANAGEMENT + "/companies/:companyId";
export const HL_MANAGE_HFUND_COMPANY =
  COMPANY_MANAGEMENT + "/companies/:companyId";
export const MANAGE_HL_COMPANY = COMPANY_MANAGEMENT + "/companies/:companyId";

export const HL_USERS = USER_MANAGEMENT + "/hl_users";
export const MANAGE_HL_USER = HL_USERS + "/:userId";

export const HF_USERS = USER_MANAGEMENT + "/hf_users";
export const REQUEST_KEY_TERM =
  "/key_term_management/:userRole/agreements/:id/key_term_request";

/*Meta */
const META = "/meta";
export const GET_SUBSCRIPTION_PLANS = META + "/subscription_plans";
export const GET_SUBSCRIPTION_SERVICES = META + "/services";
export const GET_USER_ROLES = "/role_management/:userType/roles";

export const HF_META_ONBOARDING_STATUS = META + "/onboarding_item_statuses";
export const HF_META_OTHER_STATUS = META + "/other_item_statuses";
export const AGREEMENT_STATUS = META + "/agreement_statuses";
export const KEY_TERM_GROUPS =
  META + "/agreement_types/:agreementTypeId/key_terms";
export const ANCILLARY_STATUS = META + "/ancillary_document_statuses";

export const COUNTRIES = META + "/countries";
export const PROVINCES = COUNTRIES + "/:countryId/provinces";
export const CITIES = PROVINCES + "/:provinceId/cities";

/*Counter Parties */
export const HL_COUNTER_PARTIES_MANAGEMENT = "/cp_management/hl";
export const HL_COUNTER_PARTIES =
  HL_COUNTER_PARTIES_MANAGEMENT + "/counter_parties";
export const HL_SINGLE_COUNTER_PARTY = HL_COUNTER_PARTIES + "/:counterPartyId";
export const HL_SEND_COUNTER_PARTY_INVITATION =
  HL_SINGLE_COUNTER_PARTY + "/send_invitation";

export const HF_COUNTER_PARTIES_MANAGEMENT = "/cp_management/hf";
export const HF_COUNTER_PARTIES =
  HF_COUNTER_PARTIES_MANAGEMENT + "/counter_parties";
export const HF_SINGLE_COUNTER_PARTY = HF_COUNTER_PARTIES + "/:counterPartyId";

export const HL_COUNTER_PARTIES_USERS =
  HL_SINGLE_COUNTER_PARTY + "/user_counter_parties";
export const HL_COUNTER_PARTIES_SINGLE_USER =
  HL_COUNTER_PARTIES_USERS + "/:counterPartyUserId";

export const HL_COUNTER_PARTY_GROUPS =
  HL_COUNTER_PARTIES_MANAGEMENT + "/cp_groups";
export const HF_SINGLE_COUNTER_PARTY_GROUP =
  HL_COUNTER_PARTY_GROUPS + "/:counterPartyGroupId";

/*Meta Managament */
export const META_MANAGEMENT = "/meta_management";

/*Agreements */
export const HL_META_MANAGEMENT = META_MANAGEMENT + "/hl";

export const HL_AGREEMENT_TYPES = HL_META_MANAGEMENT + "/agreement_types";
export const HL_SINGLE_AGREEMENT_TYPE =
  HL_AGREEMENT_TYPES + "/:agreementTypeId";
export const HL_REPLICATE_AGREEMENT_TYPE =
  HL_SINGLE_AGREEMENT_TYPE + "/replicate";

export const HL_KEY_TERM_GROUPS = HL_SINGLE_AGREEMENT_TYPE + "/key_term_groups";
export const HL_SINGLE_KEY_TERM_GROUP = HL_KEY_TERM_GROUPS + "/:keyTermGroupId";

export const HL_KEY_TERMS = HL_SINGLE_KEY_TERM_GROUP + "/key_terms";
export const HL_SINGLE_KEY_TERM = HL_KEY_TERMS + "/:keyTermId";

/* SUBSCRIPTION PLANS */
// export const HL_SUBSCRIPTION_PLANS = HL_META_MANAGEMENT + "/subscription_plans";
export const HL_SUBSCRIPTION_PLANS =
  LOCAL_BASE_URL + "/meta_management/hl" + "/subscription_plans";
export const HL_PAYMENT_LOGS =
  LOCAL_BASE_URL + "/subscription_management/hl/payment_logs";

/*Onboarding Item */
export const HL_ONBOARDING_ITEMS = HL_META_MANAGEMENT + "/onboarding_item_meta";
export const HL_SINGLE_ONBOARDING_ITEM =
  HL_ONBOARDING_ITEMS + "/:onboardingItemId";
export const HL_ONBAORDING_TEMPLATES =
  HL_META_MANAGEMENT + "/onboarding_templates";
export const HL_SINGLE_ONBOARDING_TEMPLATE =
  HL_ONBAORDING_TEMPLATES + "/:onboardingTemplateId";

/* Fund */
export const FUND_MANAGEMENT = "/fund_management";

export const HF_FUNDS = FUND_MANAGEMENT + "/hf/funds";
export const HF_SINGLE_FUND =
  FUND_MANAGEMENT + "/hf/companies/:companyId/funds/:fundId";
export const HF_FUNDS_ONBOARDING = HF_FUNDS + "/:fundId/onboarding_items";
export const HF_FUNDS_OTHERS = HF_FUNDS + "/:fundId/other_items";
export const HF_META_ONBOARDING_ITEM =
  HL_META_MANAGEMENT + "/onboarding_item_meta";
export const HF_META_ACCESSIBLE_USERS = HF_FUNDS + "/:fundId/accessible_users";

/* Notes */

export const NOTES_MANAGEMENT = "/note_management";
export const NOTES_TYPE_AND_ID =
  NOTES_MANAGEMENT + "/shared/notable_type/:noteType/notable_id/:noteId/notes";

/* Notification */

export const NOTIFICATION_MANAGEMENT = "/action_management";
export const NOTIFICATIONS_TYPE_AND_ID =
  NOTIFICATION_MANAGEMENT +
  "/shared/actionable_type/:actionableType/actionable_id/:actionableId/action_items";
export const NOTIFICATIONS_UPDATE_COMPLETION =
  NOTIFICATIONS_TYPE_AND_ID + "/:notificationId/complete";

/* key contacts */
export const KEY_CONTACT_MANAGEMENT = "/key_contact_management";
export const KEY_CONTACT_TYPE_AND_ID =
  KEY_CONTACT_MANAGEMENT +
  "/shared/contactable_type/:contactableType/contactable_id/:contactableId/key_contacts";

export const HL_FUNDS = FUND_MANAGEMENT + "/hl/funds";
export const HL_SINGLE_FUND =
  FUND_MANAGEMENT + "/hl/companies/:companyId/funds/:fundId";
export const HL_FUNDS_ONBOARDING = HL_FUNDS + "/:fundId/onboarding_items";
export const HL_FUNDS_OTHERS = HL_FUNDS + "/:fundId/other_items";
export const HL_META_ACCESSIBLE_USERS = HL_FUNDS + "/:fundId/accessible_users";

/* Agreement Management */
export const AGREEMENT_MANAGEMENT = "/agreement_management";
export const HF_AGREEMENTS = AGREEMENT_MANAGEMENT + "/hf/agreements";
export const HF_AGREEMENTS_ANCILLARY =
  HF_AGREEMENTS + "/:agreementId/ancillary_documents";
export const HF_AGREEMENTS_AMENDMENT =
  HF_AGREEMENTS + "/:agreementId/amendments";
export const HF_AGREEMENTS_RELATED =
  HF_AGREEMENTS + "/:agreementId/related_agreements";
export const HF_AGREEMENTS_HTML = HF_AGREEMENTS + "/:agreementId/html_content";
export const HF_AGREEMENTS_EXPORT = HF_AGREEMENTS + "/xlsx";
export const HF_AGREEMENTS_ANCILLARY_EXPORT = HF_AGREEMENTS_ANCILLARY + "/xlsx";
export const HF_AGREEMENTS_RELATED_EXPORT = HF_AGREEMENTS_RELATED + "/xlsx";
export const HF_AGREEMENTS_DOX_COINS_REQUEST_KEY_TERMS =
  "/dox_coin_management/hf/service_dox_coins";

export const HL_AGREEMENTS = AGREEMENT_MANAGEMENT + "/hl/agreements";
export const HL_AGREEMENTS_ANCILLARY =
  HL_AGREEMENTS + "/:agreementId/ancillary_documents";
export const HL_AGREEMENTS_AMENDMENT =
  HL_AGREEMENTS + "/:agreementId/amendments";
export const HL_AGREEMENTS_RELATED =
  HL_AGREEMENTS + "/:agreementId/related_agreements";
export const HL_AGREEMENTS_EXPORT = HL_AGREEMENTS + "/xlsx";
export const HL_AGREEMENTS_ANCILLARY_EXPORT = HL_AGREEMENTS_ANCILLARY + "/xlsx";
export const HL_AGREEMENTS_RELATED_EXPORT = HL_AGREEMENTS_RELATED + "/xlsx";
export const HL_AGREEMENTS_HTML = HL_AGREEMENTS + "/:agreementId/html_content";
export const HL_AGREEMENTS_DOWNLOAD_AMENDMENTS = HL_AGREEMENTS + "/:agreementId/download_amendments";


/* Key term management  */
export const KEY_TERM_MANAGEMENT = "/key_term_management";
export const HF_AGREEMENT_KEY_TERMS = KEY_TERM_MANAGEMENT + "/hf/agreements";
export const HF_AGREEMENT_KEY_TERM =
  HF_AGREEMENT_KEY_TERMS + "/:agreementId/agreement_key_terms";
export const HL_AGREEMENT_KEY_TERMS = KEY_TERM_MANAGEMENT + "/hl/agreements";
export const HF_AGREEMENT_KEY_TERM_REQUEST =
  HF_AGREEMENT_KEY_TERMS + "/:agreementId/key_term_request";
export const HL_AGREEMENT_KEY_TERM_GROUPS =
  HL_AGREEMENT_KEY_TERMS + "/:agreementId/key_term_groups";
export const HL_AGREEMENT_KEY_TERM =
  HL_AGREEMENT_KEY_TERMS + "/:agreementId/agreement_key_terms";
export const HL_AGREEMENT_KEY_TERM_DISCARD = HL_AGREEMENT_KEY_TERM + "/discard";
export const HL_AGREEMENT_KEY_TERM_IMPORT = HL_AGREEMENT_KEY_TERM + "/import";
export const HL_AGREEMENT_KEY_TERM_MARK_COMPLETE =
  HL_AGREEMENT_KEY_TERM + "/mark_complete";

/* Global Search */
export const GLOBAL_SEARCH = "/global_search";
export const GLOBAL_SEARCH_RESULTS = GLOBAL_SEARCH + "/search_results";
export const GLOBAL_SEARCH_TERMS = GLOBAL_SEARCH + "/search_terms";

/* Support Management */

export const HF_TICKETS = "/support_management/hf/tickets";
export const HF_TICKET = "/support_management/hf/tickets/:ticketId";
export const HL_TICKETS = "/support_management/hl/tickets";
export const HL_TICKET = "/support_management/hl/tickets/:ticketId";

export const HF_FAQS = "/support_management/hf/f_a_qs";

export const HL_FAQS = "/support_management/hl/f_a_qs";
export const HL_FAQ = "/support_management/hl/f_a_qs/:faqId";

export const HF_VIDEOS = "/support_management/hf/videos";

export const HL_VIDEOS = "/support_management/hl/videos";
export const HL_VIDEO = "/support_management/hl/videos/:videoId";

export const HF_PARTNERS = "/support_management/hf/partners";

export const HL_PARTNERS = "/support_management/hl/partners";
export const HL_PARTNER = "/support_management/hl/partners/:partnerId";

/* Report Management */

export const HF_REPORTS = "/report_management/hf/reports";
export const HF_REPORTS_SPECIFIC = "/report_management/hf/reports/:reportId";
export const HF_REPORTS_SPECIFIC_RESULTS =
  "/report_management/hf/reports/:reportId/report_results";

export const HL_REPORTS_COMPANIES = "/report_management/hl/companies";
export const HL_REPORTS_AGREEMENTS = "/report_management/hl/agreements";
export const HF_REPORTS_AGREEMENTS = "/report_management/hf/agreements";
export const HF_REPORTS_HISTORY =
  "/report_management/hf/reports/:reportId/report_agreements";
export const HF_REPORTS_SPECIFIC_EXCEL =
  "/report_management/hf/reports/:reportId/download_url";
export const HF_REPORTS_SPECIFIC_DOWNLOAD =
  "/report_management/hf/reports/:reportId/download";

export const HL_REPORTS = "/report_management/hl/reports";
export const HL_REPORTS_SPECIFIC = "/report_management/hl/reports/:reportId";
export const HL_REPORTS_HISTORY =
  "/report_management/hl/reports/:reportId/report_agreements";
export const HL_REPORTS_SPECIFIC_RESULTS =
  "/report_management/hl/reports/:reportId/report_results";
export const HL_REPORTS_SPECIFIC_EXCEL =
  "/report_management/hl/reports/:reportId/download_url";
export const HL_REPORTS_SPECIFIC_DOWNLOAD =
  "/report_management/hl/reports/:reportId/download";

// Dox Coins HF
// export const DOX_POINTS_HISTORY = STUB_BASE_URL + "/dox_coin_management/hf/dox_coin_transactions";
// export const DOX_REDEEMED_SERVICES = STUB_BASE_URL + "/dox_coin_management/hf/services";
// export const DOX_ALLOCATION = STUB_BASE_URL + "/dox_coin_management/hf/dox_coin_allocations";
// export const TOTAL_DOX_COINS = STUB_BASE_URL + "/dox_coin_management/hf/company_dox_coins";
// export const DOX_COIN_IN_CIRCULATION = STUB_BASE_URL + "/dox_coin_management/hl/company_dox_coins";
// export const DOX_CREATE_SERVICES = STUB_BASE_URL + "/dox_coin_management/hl/services";
// export const DOX_MECHANISM_UPDATE = STUB_BASE_URL + "/dox_coin_management/hl/dox_coin_allocations";

export const DOX_POINTS_HISTORY =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/dox_coin_transactions";
export const DOX_REDEEMED_SERVICES =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/services";
export const DOX_ALLOCATION =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/dox_coin_allocations";
export const TOTAL_DOX_COINS =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/company_dox_coins";
// hl
export const DOX_COIN_IN_CIRCULATION =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/company_dox_coins";
export const DOX_CREATE_SERVICES =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/services";
export const DOX_MECHANISM_UPDATE =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/dox_coin_allocations";
export const REDEEM_DOX_COIN =
  LOCAL_BASE_URL + "/dox_coin_management/:userRole/services/:id/redeem";
export const UPDATE_DOX_COINS =
  LOCAL_BASE_URL + "/dox_coin_management/hl/company_dox_coins";

// DISCUSSION BOARD
export const LIST_TOPICS =
  LOCAL_BASE_URL + "/discussion_board/:userRole/topics";
export const LIST_MY_TOPICS =
  LOCAL_BASE_URL + "/discussion_board/:userRole/my_topics";
export const LIST_COMPANY_TOPICS =
  LOCAL_BASE_URL + "/discussion_board/:userRole/my_company_topics";
export const LIST_TOPIC_COMMENT =
  LOCAL_BASE_URL + "/discussion_board/:userRole/topics/:id/replies";
export const LIST_COMMENT_REPLIES =
  LOCAL_BASE_URL + "/discussion_board/:userRole/replies/:id/replies";
export const LIKE_COMMENT =
  LOCAL_BASE_URL + "/discussion_board/:userRole/replies/:id/like";
export const DIS_LIKE_COMMENT =
  LOCAL_BASE_URL + "/discussion_board/:userRole/replies/:id/dislike";
export const DELETE_REPLY =
  LOCAL_BASE_URL + "/discussion_board/:userRole/replies";
export const POST_REVIEW =
  "/cp_management/:userRole/counter_parties/:id/review";
export const VIEW_TOPIC = "/discussion_board/:userRole/topics/:id";

// Notification
export const LIST_OTHER_NOTIFICATIONS =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/other_notifications";
export const LIST_AGREEMENT_NOTIFICATIONS =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/agreement_notifications";
export const LIST_ANCILLARY_NOTIFICATIONS =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/ancillary_document_notifications";
export const LIST_ONBOARDING_NOTIFICATIONS =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/onboarding_item_notifications";
export const LIST_NOTIFICATION_META =
  LOCAL_BASE_URL + "/meta_management/hl/notification_meta";
export const DELETE_NOTIFICATION =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/other_notifications/mark_deleted";
export const NOTIFICATION_MARK_AS_VIEWED =
  LOCAL_BASE_URL +
  "/action_management/shared/notifications/other_notifications/mark_seen";
// export const SHARED_NOTIFICATION_MANAGEMENT = STUB_BASE_URL + "/action_management/shared/:notifiableType/action_type/:notifiableId/notifiable_id/notifications";

//List Audit trails
export const LIST_AUDIT_TRIALS =
  LOCAL_BASE_URL + "/audit_trail_management/hl/audit_trails";
export const GET_AUDIT_TRAIL_VIEW =
  LOCAL_BASE_URL + "/audit_trail_management/hl/audit_trails/show";
export const UNDO_AUDIT_TRAIL =
  LOCAL_BASE_URL + "/audit_trail_management/:userRole/audit_trails/undo";

// User Management
export const HF_USER_MANAGEMENT =
  LOCAL_BASE_URL + "/user_management/hf/users";
export const HL_USER_MANAGEMENT =
  LOCAL_BASE_URL + "/user_management/hl/hf_users";
export const AGREEMENT_TYPES =
  LOCAL_BASE_URL + "/agreement_management/:userRole/agreement_types";
export const APP_AGREEMENT_STATUS =
  LOCAL_BASE_URL + "/agreement_management/:userRole/agreement_statuses";
export const GLOBAL_SEARCH_KEY_TERMS = LOCAL_BASE_URL + "/global_search/key_terms";
export const GLOBAL_SEARCH_DOCUMENTS = LOCAL_BASE_URL + "/global_search/documents";
export const DASHBOARD_STATS_HL = LOCAL_BASE_URL + "/analytics_management/hl/overall_stats";
